<template>
  <div class="ecommerce-application">
    <b-overlay rounded="sm" :show="show">
      <div class="list-view product-checkout mt-0">
        <div class="checkout-items order-last order-lg-first">
          <b-card>
            <form ref="formWO">
              <input type="hidden" name="id" v-model="order.id">              
              <b-row class="mb-1">
                <b-col md="6" class="text-center">
                  <h4>Inicio Servicio</h4>
                  <b-form-input readonly type="datetime-local" v-model="order.date_start" name="date_start"></b-form-input>
                </b-col>
                <b-col md="6" class="text-center">
                  <h4>Fin Servicio</h4>
                  <b-form-input type="datetime-local" v-model="order.closing_date" name="closing_date" @change="updateForm"></b-form-input>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col md="4" class="text-center">
                  <h4>Foto antes servicio</h4>
                  <div class="mt-75 ml-75">
                    <ul class="text-left">
                      <li v-for="file,index in files.before_pic">
                        <a :href="$http.defaults.baseURL + 'storage/app/private/' + file.download_link " target="_blank" >
                          <small>{{ file.original_name }}</small>
                        </a>
                        <feather-icon class="ml-1"  icon="XCircleIcon" size="16" @click="removePic( 'before_pic',file.original_name, index)" />
                      </li>
                    </ul>
                    <b-form-file browse-text= "Buscar" placeholder="" v-model="beforePic" class="mb-1"  accept=".jpg, .png, .gif" ref="before_pic"> </b-form-file>
                    <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('before_pic')"> Subir</b-button>
                    <b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="beforePic = null"> Reset </b-button>
                    </div>
                </b-col>
                <b-col md="4" class="text-center">
                  <h4>Foto durante servicio</h4>
                  <div class="mt-75 ml-75">
                    <ul class="text-left">
                      <li v-for="file,index in files.progress_pic">
                        <a :href="$http.defaults.baseURL + 'storage/app/private/' + file.download_link " target="_blank">
                          <small>{{ file.original_name }}</small>
                        </a>
                        <feather-icon class="ml-1"  icon="XCircleIcon" size="16" @click="removePic( 'progress_pic',file.original_name, index)" />
                      </li>
                    </ul>
                    <b-form-file browse-text= "Buscar" placeholder="" class="mb-1" v-model="progressPic" accept=".jpg, .png, .gif" ref="progress_pic"> </b-form-file>
                    <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('progress_pic')"> Subir</b-button>
                    <b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="progressPic = null"> Reset </b-button>
                  </div>
                </b-col>
                <b-col md="4" class="text-center">
                  <h4>Foto despues servicio</h4>
                  <div class="mt-75 ml-75">
                    <ul class="text-left">
                      <li v-for="file,index in files.after_pic">
                        <a :href="$http.defaults.baseURL + 'storage/app/private/' + file.download_link " target="_blank">
                          <small>{{ file.original_name }}</small>
                        </a>
                        <feather-icon class="ml-1"  icon="XCircleIcon" size="16" @click="removePic( 'after_pic',file.original_name,index)" />
                      </li>
                    </ul>
                    <b-form-file browse-text= "Buscar" placeholder="" class="mb-1" v-model="beforePic" accept=".jpg, .png, .gif" ref="after_pic"> </b-form-file>
                    <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="updatePictures('after_pic')"> Subir</b-button>
                    <b-button variant="outline-danger" size="sm" class="mb-75 mr-75" @click="AfterPic = null"> Reset </b-button>
                  </div>
                  </b-col>              
              </b-row>
            </form>
          </b-card>
          <b-card>
            <h4 class="card-title">Datos Cliente
              <b-button variant="success" class="btn-sm float-right" @click="editClient(order.client_id)">Editar</b-button>
            </h4>
            <hr>
            <h5 class="font-weight-bold">{{order.client.name}}</h5>
            <p class="" v-if="order.client.nif">NIF: {{order.client.nif}}</p>
            <p class="font-weight-bold" v-if="order.client.contact">Contacto: {{order.client.contact}}</p>            
            <p> {{order.client.phone ? order.client.phone : '' + ' '+ (order.client.phone_aux ? order.client.phone_aux : '')}}</p>
          </b-card>
          <b-card  title="Presupuestos">
            <b-list-group >
              <b-link :href=" item.status == 0 && !is_budgeteable ? '#' : '/budget/' + item.id " v-for="item,index in order.budgets" :key="index">
                <b-list-group-item>  
                   <b-badge pill :variant="status_budget[item.status].class" class="mr-1" v-if="!show">{{status_budget[item.status].text}}</b-badge> {{item.doc_num}} 
                </b-list-group-item>                                
              </b-link>
            </b-list-group>
            <b-button class="mt-1" :to="'/create-budget/' + order_id" variant="primary" block v-if="is_budgeteable">Crear presupuesto</b-button>
          </b-card>

          <b-card v-if="order.delivery" title="Albarán">
            <b-list-group>
              <b-link :to="'/delivery/' + order.delivery.id" >
                <b-list-group-item>  
                   <b-badge pill :variant="status_delivery[order.delivery.status].class" class="mr-1" v-if="!show">{{status_delivery[order.delivery.status].text}}</b-badge> {{order.delivery.doc_num}} 
                </b-list-group-item>                                
              </b-link>
            </b-list-group>            
          </b-card>
          <b-card >
            <h4 class="card-title">Materiales
              <button class="btn btn-success btn-sm float-right" @click="editExpenses(-1)"  v-if="!is_validate">
                <feather-icon size="12" icon="PlusIcon" class="cursor-pointer"/>
              </button>
            </h4>
            <b-list-group>
                <b-list-group-item @click="editExpenses(index)" v-for="item,index in order.work_order_expenses" :key="index">
                  <b-row>
                    <b-col cols="9">{{item.description}}</b-col>
                    <b-col cols="3" class="text-right">{{item.ptotal}}</b-col>
                  </b-row>
                </b-list-group-item>                                
            </b-list-group>
          </b-card>
          <b-card>
            <h4 class="card-title">Notas<button class="btn btn-success btn-sm float-right" @click="addNote()"><feather-icon size="12" icon="PlusIcon" class="cursor-pointer"/></button></h4>
            <b-list-group>
                <b-list-group-item @click="editNote(index)" v-for="item,index in order.notes" :key="index"> 
                  <b-row>
                    <b-col cols="9">{{item.note}}</b-col>
                    <b-col cols="3" class="text-right">{{item.author}}</b-col>
                  </b-row>
                    <span class="text-right text-muted"> <small>{{ formatTime(item.created_at)}}</small></span>
                </b-list-group-item>                                
            </b-list-group>
          </b-card>
        </div>
        <div class="checkout-options">
          <b-card class="position-relative client-card">  
            <h5 class="card-title mb-25"> {{ order.doc_num }} {{' ' + order.title ? order.title : ''}} 
              <b-badge pill :variant="status[order.status].class" class="ml-3" v-if="!show">{{status[order.status].text}}</b-badge>
            </h5>                       
            <hr>
            <template v-if="order.address1">
              <h5> <strong>Dirección Actuación</strong></h5>
              <p class="mb-0"> {{order.cp_full_address}}</p>
              <hr>
            </template>
            <template v-if="order.phone || order.other_phones">
              <h5><strong>Teléfonos</strong></h5>
              <span v-if="order.phone"><strong>Principal: </strong>{{ order.phone }}<br></span>
              <span v-if="order.other_phones"><span v-if="order.phone"><strong >Otros:</strong><br></span>
              <p v-html="order.other_phones"></p>  </span>
              <hr>
            </template>
            <h5 v-if="order.charged_by >= 0"> <strong>Cobra</strong> {{arrChargedBy[order.charged_by]}}</h5>
            <p class="" v-if="order.company">{{order.company.rate_type == 0 ? 'Clientes fijos/tienda (Dto. 20%)' : (order.company.rate_type == 1 ? 'Clientes repiten (Dto. 10%)' : 'Clientes de urgencia')}}</p>            
            <hr>
            <h5>Descripción</h5>
            <p>{{order.description}}</p>
            <b-button variant="info" block class="mt-2" v-if="order.status == 10" @click="changeStatus(11)">Reclamación Atendida</b-button>  
            <label>Citar para:</label> 
              <b-form-input type="datetime-local" v-model="fecha_cita" @change="updateMeeting(fecha_cita)"></b-form-input>
              <b-button @click="ValidateExpense()" class="mt-1" type="button" variant="success" block v-if="!is_validate && is_done">Validar Orden</b-button>
            <!-- <b-button variant="success" block :to="'/new-work-order/' + order_id" class="mt-2" >Crear nueva orden</b-button>             -->
          </b-card>
        </div>
      </div>
    </b-overlay>
      <!-- <b-modal ref="image" hide-footer header-bg-variant="light" modal-class="modal-fullscreen" scrollable>
        <img class="img-fluid" :src="show_picture" alt="imagen ampliada">
     </b-modal> -->
      <!-- The modal for Expenses -->
      <b-modal id="add-expenses-modal" title="Materiales / Gastos">
        <label>Cantidad</label><b-form-input type="number" v-model="expenses.qty" @change="recalcExpense"  :readonly="is_validate"></b-form-input>
        <label class="mt-1">€/unidad</label><b-form-input type="number" v-model="expenses.punit" @change="recalcExpense"  :readonly="is_validate"></b-form-input>
        <label class="mt-1">Total</label><b-form-input type="number" :value="expenses.ptotal" readonly></b-form-input>
        <label class="mt-1">Suministrado por</label><b-form-input type="text" v-model="expenses.origen"  :readonly="is_validate"></b-form-input>
        <label class="mt-1">Descripción</label><b-form-textarea v-model="expenses.description"   :readonly="is_validate"/>
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="save_expenses"  v-if="!is_validate">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>
      <!-- The modal for edit Client -->
  
      <b-modal id="edit-client-modal" title="Editar datos cliente">
        <label class="mt-1">Nombre Fiscal</label><b-form-input type="text" name="name" v-model="client.name"></b-form-input>
        <label class="mt-1">NIF</label><b-form-input type="text" v-model="client.nif"></b-form-input>       
        <label class="mt-1">Persona contacto</label><b-form-input type="text" v-model="client.contact"></b-form-input>      
        <label for="aux-phone" class="mt-1">Teléfono contacto</label><b-form-input id="aux-phone" type="text" v-model="client.phone_aux" :state="is_valid_phone_aux"></b-form-input>   
        <div v-if="is_valid_phone_aux == false" id="auxphone-feedback" class="feedback-form">
          El teléfono no debe contener espacios ni carácteres especiales (puede contener un + al principio)
        </div>
        <label for="phone" class="mt-1">Teléfono</label><b-form-input id="phone" type="text" v-model="client.phone" :state="is_valid_phone"></b-form-input>   
        <div v-if="is_valid_phone == false" id="phone-feedback" class="feedback-form">
          El teléfono no debe contener espacios ni carácteres especiales (puede contener un + al principio)
        </div>    
        <label class="mt-1">Email</label><b-form-input type="text" v-model="client.email"></b-form-input>       
        <label class="mt-1">Dirección</label><b-form-input type="text" v-model="client.address1" ></b-form-input>
        <label class="mt-1">Piso</label><b-form-input type="text" v-model="client.address2" ></b-form-input>
        <label class="mt-1">Población</label>
        <v-select
          v-model="optionsLocationSelected"
          @search="onSearchLocation"
          :options="optionsLocation"
          label="name"
         />        
        <!-- <label class="mt-1">Población</label><b-form-input type="text" v-model="client.cp_municipality_id"></b-form-input> -->
        <label class="mt-1">Código postal</label><b-form-input type="text" v-model="client.cp"></b-form-input>       
          <template #modal-footer="{cancel}">
            <b-row class="w-100">
              <b-col md="4">
                <button class="btn btn-success btn-sm btn-block mb-1" @click="saveClient">Aceptar</button>            
              </b-col>
              <b-col md="4">
                <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
              </b-col>
            </b-row>
          </template>
      </b-modal>
      <!-- The modal for add NOTES -->
      <b-modal id="add-note-modal" title="Añadir Nota">
        <form ref="formNotes">
          <input type="hidden" name="author" :value="user.name">
          <input type="hidden" name=" work_order_id" :value="order.id">
          <b-form-textarea name="note" v-model="note.note" class="mb-1" required/>
          <b-img v-if="optionsLocal.note != null" 
                      :src="(optionsLocal.note.startsWith('data') ? '' : $http.defaults.baseURL + 'storage/app/private/') + optionsLocal.note"
                       height="80" />       
          <div class="mt-75 ml-75">
              <b-button variant="outline-success" size="sm" class="mb-75 mr-75" @click="$refs.refInputNote.$el.click()">Subir Imagen</b-button>
              <b-form-file ref="refInputNote" v-model="note.attached" name="attached" accept=".jpg, .png, .gif" :hidden="true" plain @input="inputNoteImageRenderer" />   
          </div>
        </form>
        <template #modal-footer="{cancel}">
          <b-row class="w-100">
            <b-col md="4">
              <button class="btn btn-success btn-sm btn-block mb-1" @click="storeNote">Aceptar</button>            
            </b-col>
            <b-col md="4">
              <button  class="btn btn-secondary btn-sm btn-block" @click="cancel()">Cancelar</button>
            </b-col>
          </b-row>
        </template>
      </b-modal>
      <!-- The modal for view NOTES -->
      <b-modal id="edit-note-modal" title="Ver Nota" :hide-footer="true">
        <div class="text-center">
          <BImg v-if="note.attached" :src="$http.defaults.baseURL + 'storage/app/private/' + note.attached " alt="" height="80"></BImg>
        </div>
        <b-form-textarea :value="note.note" class="my-1" readOnly/>
        <small>{{note.author}} <span class="text-muted">( {{ formatTime(note.created_at)}} )</span></small>
      </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'  
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { BFormTextarea,VBModal, BRow, BImg, BCol, BCard, BListGroup, BCardText, BFormFile, BListGroupItem, BBadge, BOverlay, BCardBody, BLink, BButton, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody } from "bootstrap-vue";
export default {
  components: { vSelect, BFormTextarea,VBModal, BRow, BImg, BCol, BCard, BCardText, BListGroup, BFormFile, BListGroupItem, BBadge, BCardBody, BLink, BButton, BOverlay, BFormInput, BInputGroup, BMedia, BMediaAside, BMediaBody },
  data() {
    return {
      show: true,
      note : {note : '', author : '' , index : -1 },
      client : {name : '', address1 : '', address2 : '', cp_municipality_id : '', cp : '', phone: '', index : -1 },
      expenses : {description : '', qty : 1, punit : 0, ptotal : 0, origen : '', index : -1 },
      afterPic: null,
      progressPic : null,
      beforePic: null,
      files : { after_pic : null, before_pic : null , progress_pic : null },
      fecha_cita: null,
      order_id : this.$route.params.order,
      order : { client : {}, budgets : [], notes : {} , work_order_expenses : {} },
      optionsLocation: [],
      optionsLocationSelected: null,
      arrChargedBy : ["operario", "empresa", "servipro", "no especificado"],
      status : [
        {text :'Pendiente Asignación', class : 'primary'},
        {text :'Asignada', class : 'info'},
        {text :'Aceptada', class : 'warning'},
        {text :'Citado', class : 'info'},
        {text :'Presupuestado', class : 'secondary'},
        {text :"Presupuesto Aceptado", class : 'success'},
        {text :"Hecho", class : 'success'},
        {text :"Validada", class : 'success'}, 
        {text :"OT Rechazada", class : 'danger'},
        {text :"Reclamación", class : 'warning'},
        {text :"Reclamación Atendida", class : 'success'},
      ],
        status_budget : [
        {text :'nuevo', class : 'secondary'},
        {text :'aceptado', class : 'success'},
        {text :'rechazado', class : 'danger'},
       ],        
        status_delivery : [
        {text :'Pendiente', class : 'secondary'},
        {text :'Conforme', class : 'success'},
        {text :'Rechazado', class : 'danger'},
        {text :'Facturado', class : 'success'},
       ],        
    }
  },
  created() {
    this.$http.get("/api/getOrder?id=" + this.order_id).then((response) => {
      this.order = response.data;
      this.fecha_cita = this.order.appointment_date ? new Date(this.order.appointment_date).toISOString().slice(0,16) : null;  
      this.files.before_pic = this.order.before_pic ? JSON.parse(this.order.before_pic) : null;
      this.files.progress_pic = this.order.progress_pic ? JSON.parse(this.order.progress_pic) : null;
      this.files.after_pic = this.order.after_pic ? JSON.parse(this.order.after_pic) : null;
      if(this.order.closing_date){
        this.order.closing_date = new Date(this.order.closing_date).toISOString().slice(0,16);        
      }      
      if(!this.order.date_start){
        let now = new Date;
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        this.order.date_start = now.toISOString().slice(0,16);
        setTimeout(() => this.updateForm(), 500);
      }else{
        this.order.date_start = new Date(this.order.date_start).toISOString().slice(0,16);
      }
      this.optionsLocal = { note : null }      
      this.show = false
    })

  },

  methods: {   
    // buscar la población
    onSearchLocation(search, loading){
      loading(true);
      this.$http.get(`/api/getLocation?q=${search}`).then((response) => {
        this.optionsLocation = response.data
        loading(false);
      });
    },
    updateMeeting(){
      this.$http.post("/api/updateMeeting", {id : this.order.id, fecha: this.fecha_cita}).then((response) => {           
        this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success',noCloseButton : true });
      })
        .catch(response => { window.console.log(response) });
    },
    changeStatus(status){     
      this.$http.post("/api/updateStatus", {id : this.order.id, status : status }).then((response) => {     
        this.order.status = response.data;        
        this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success',noCloseButton : true });
      })
        .catch(response => { window.console.log(response) });
    },
    formatTime(date){
      let format_date = new Date(date).toLocaleDateString('es-es',{ year: 'numeric',month: '2-digit',day: '2-digit'}) 
      let format_time = new Date(date).toLocaleTimeString('es-es',{ hour : '2-digit', minute : '2-digit'});
      return  format_date + ' - ' + format_time 
    },    
    updateForm () {
      this.show = true;
      var formData = new FormData(this.$refs.formWO);
      this.$http({
        method: "post",
        url: "/api/updateWo",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { 
        this.show = false;       
        this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success',noCloseButton : true });
      })
    },

    // FOTOS 

    updatePictures(source)
    {
      if( ! this.$refs[source].files[0]){
        this.$bvModal.msgBoxOk('No hay imagen seleccionada').then(value => {}).catch(err => {});
       return
      }
      const formData = new FormData();
      formData.append('order_id', this.order.id);
      formData.append('source', source);
      formData.append('file', this.$refs[source].files[0]);
      const headers = { 'Content-Type': 'multipart/form-data' };
      this.$http.post("/api/addPic", formData, { headers }).then((response) => {
        this.$refs[source].reset();
        this.files[source] = response.data;
      });      
    },
    removePic(source,file,index) {
      this.$bvModal.msgBoxConfirm('¿Desea eliminar el archivo?',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {      
          let params = { slug:'work-orders', filename:  file, id: this.order.id, field: source, multi: true }      
          this.$http.post("/api/removePic",params).then((response) => {
              this.files[source].splice(index,1)
              // console.log(response.data);
            });  
          }
        })   
    },

    // ************************* Gastos (Material)
    recalcExpense() {
      let subtotal = parseFloat(this.expenses.qty) * parseFloat(this.expenses.punit);
      this.expenses.ptotal = (subtotal.toFixed(2));
    },
    editExpenses(index) {
      this.expenses.index = index;
      if (index != -1) {
        this.expenses = {
          description : this.order.work_order_expenses[index].description,
          qty : this.order.work_order_expenses[index].qty,
          punit : this.order.work_order_expenses[index].punit,       
          ptotal : this.order.work_order_expenses[index].ptotal,
          origen : this.order.work_order_expenses[index].origen
        }
      } else {
        this.expenses = {description : '', qty : 1, punit : 0, ptotal : 0, origen : ''}
      }
      this.expenses.index = index;
      this.$bvModal.show('add-expenses-modal')
    },
    ValidateExpense() {
      this.$bvModal.msgBoxConfirm('¿Desea validar el servicio? No podra volver a modificar estos datos',{size: 'sm',buttonSize: 'sm',okVariant: 'danger',okTitle: 'SI',cancelTitle: 'NO',footerClass: 'p-2',hideHeaderClose: false,centered: true})
      .then(value => {
        if(value) {
          this.order.status = 7;
          this.changeStatus(7); 
          }
        })   
    },
    //CLIENTE
    editClient(index) {  
      this.client = this.order.client;
      this.optionsLocation =  this.client.location ? [{name : this.client.location.name, id : this.client.location.id}] : [{}];
      this.optionsLocationSelected = this.client.location
      this.$bvModal.show('edit-client-modal')
    },
    validate () {
      for (let i = 0; i < this.client.length; i++) {
        if(!this.client[i] == ""){
          return false
        } 
      }
      return true;
    },
    saveClient : async function (e) {   
      
      if (!this.optionsLocationSelected) {
        this.$bvToast.toast('ERROR : Población es un campo obligatorio', { variant: 'danger',noCloseButton : true });
        
      }
      this.client.cp_municipality_id = this.optionsLocationSelected.id
      let that = this
      this.$http.post("/api/updateClient",this.client).then((response) => {
        if (response.data) {
          that.order.client = that.client = response.data
          that.$forceUpdate();
        }else {
          alert('Los datos no han podido ser almacenados')
        }
        this.$bvModal.hide('edit-client-modal')
        });      
    },
    save_expenses() {
      let that = this
      this.expenses.id = this.expenses.index != -1 ? this.order.work_order_expenses[this.expenses.index].id : 0;
      this.expenses.work_order_id = this.order.id
      this.$http.post("/api/updateExpenses",this.expenses).then((response) => {
              if (response.data) {
                this.$bvModal.hide('add-expenses-modal')
                if (that.expenses.index != -1) {
                  that.order.work_order_expenses[that.expenses.index] = response.data
                } else {
                  that.order.work_order_expenses.unshift(response.data);
                }
                that.$forceUpdate();
              }else {
                alert('Los datos no han podido ser almacenados')
              }
           });       
    },
    // ****************  Anotaciones
    addNote() {
      this.note = {note : '', attached : null , index : -1 }
      this.$bvModal.show('add-note-modal')
    },
    editNote(index) {
      this.note = this.order.notes[index]
      this.$bvModal.show('edit-note-modal')
    },
    storeNote : async function (e) {
      this.$bvModal.hide('add-note-modal')
      if(!this.note.note) {
        this.$bvToast.toast('No se ha podido guardar la anotación. El campo de texto es obligatorio', { variant: 'danger',noCloseButton : true });
        return
      }
      this.show = true;
      var formData = new FormData(this.$refs.formNotes);
      await this.$http({
        method: "post",
        url: "/api/insertNote",
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } }
      }).then(response => { 
        this.show = false;
        this.order.notes.unshift(response.data);
        this.$bvToast.toast('Cambios guardados con éxito', { variant: 'success',noCloseButton : true });
      })
        .catch(response => { window.console.log(response) });
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    is_validate: function () {
      return this.order.status == 7 ;
    },
    is_done: function () {
      return this.order.status == 6 ;
    },
    is_budgeteable: function () {
     return !this.order.budgets.some(budget => budget.status == 1);
    },
    is_valid_phone: function () {
      const regex = new RegExp('^[+]?[0-9]*$');
      if (!this.client.phone || this.client.phone.length == 0) return null;
      return regex.test(this.client.phone || "");
    },
    is_valid_phone_aux: function () {
      const regex = new RegExp('^[+]?[0-9]*$');
      if (!this.client.phone_aux || this.client.phone_aux.length == 0) return null;
      return regex.test(this.client.phone_aux || "");
    }
  },
  setup() {
    const refInputNote = ref(null)
    const optionsLocal = ref(JSON.parse( JSON.stringify({  note: null   }) ));
      const { inputImageRenderer: inputNoteImageRenderer } = useInputImageRenderer(refInputNote, 
      base64 => { 
        // eslint-disable-next-line no-param-reassign
        optionsLocal.value.note = base64;
      })
    return {
      refInputNote,
      optionsLocal,
      inputNoteImageRenderer
    }
  },  
};
</script>

<style lang="scss" >
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';

.client-card p {
  margin-bottom: 0.25rem ;
  font-size: 0.9rem;
}
.feedback-form{
  color:red;
  font-size: 0.8rem;
}
</style>
